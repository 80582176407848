import React, { Component, useEffect, useState } from "react";
import env from "../../../environment.json";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons";
import { faSortAlt } from "@fortawesome/pro-solid-svg-icons";
import {
  faCalendarPlus,
  faChevronUp,
  faChevronDown,
  faUserPlus,
  faSpinner,
  faCircleUp,
} from "@fortawesome/pro-light-svg-icons";
import { faSortAlt as faSortAltSorted } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import helperfunctions, { capitalize, showDate, pushMedia } from "../../../assets/helperFunctions";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import empty from "../../../assets/images/empty.jpg";
import "./ActivityDisplay.scss";
import {
  approvalStatusChange,
  ChangeApprovalScreen,
  WhatsonApprovalStatusButtons,
} from "../WhatsonDisplay/WhatsonContent";
import VfiCheckbox from "../../../assets/VfiCheckbox";
import PreviewWhatson from "../PreviewWhatson/PreviewWhatson";
import OptionsList from "../../../assets/OptionsList/OptionsList";
import { clone, cloneDeep } from "lodash";
import RightSideInfo from "../../../assets/RightSideInfo";
import ReactDatePicker from "react-datepicker";
import { GlobalToolTipController } from "../../ToolTips/GlobalToolTip";
import ToolTip from "../../ToolTips/ToolTip";
import EditPage from "../Pages/EditPage/EditPage";
import MediaSettings from "../MediaDisplay/MediaSettings";
import axios from "axios";
import { activityIconShow } from "./mappers";

class Activity extends Component {
  _isMounted = false;
  _source = axios.CancelToken.source();

  constructor(props) {
    super(props);
    const preDate = new Date();
    preDate.setMonth(preDate.getMonth() - 6);
    this.state = {
      show: "activities",
      sortedBy: "date",
      reverse: true,
      searchValue: "",
      showItems: 15,
      activity: [],
      awaiting_approval: [],
      awaiting_approval_refresh: 0,
      allActivityHidden: false,
      awaitingApprovalHidden: false,
      listIsOpen: false,
      optionsListOptions: [],
      optionsListPos: { x: 0, y: 0 },

      whatWhatson: "",
      whatsonId: 0,
      showTable: "awaiting",
      filterOption: "all",
      filters: {
        searchValue: "",
        fromDate: preDate,
        toDate: new Date(),
        sites: [],
        regions: [],
        activity_types: [],
        awaiting_status: [],
      },
      sites: [],
      regions: [],
      filterShown: false,
      expandedActivities: [],
      expandedShownAmount: {},
      changeDecisionInfo: { type: "", id: 0, value: "", state: "index" },
      loading: false,
      activityTypes: [
        {
          name: "Pages",
          accessType: "sites",
          endpointTypeName: "page",
        },
        {
          name: "Articles",
          accessType: "articleTypes",
          endpointTypeName: "article",
        },
        {
          name: "Events",
          accessType: "events",
          endpointTypeName: "event",
        },
        {
          name: "Places",
          accessType: "places",
          endpointTypeName: "place",
        },
        {
          name: "Tours",
          accessType: "tours",
          endpointTypeName: "tour",
        },
        {
          name: "Tour providers",
          accessType: "tourOperators",
          endpointTypeName: "tour operator",
        },
        {
          name: "Hiking",
          accessType: undefined,
          endpointTypeName: "hike",
        },
        {
          name: "Media",
          accessType: undefined,
          endpointTypeName: "media",
        },
        {
          name: "Users",
          accessType: undefined,
          endpointTypeName: "user",
        },
      ],
    };

    this.activity = [];
    // this.sites = this.props.listOptions.filter((element) => element.index !== "0")
    this.sitesChecked = [];

    this.filterContent = [
      {
        headline: "Date",
        value: [{ startDate: preDate, endDate: new Date() }],
        style: "date",
      },
      {
        headline: "Sites",
        value: [],
        style: "checkbox",
      },
    ];
    // this.search = { bool: true, searchValue: "" };
  }

  getActivities = () => {
    this.setState({ loading: true });
    const protocolAndHost = env.protocol + env.env;
    axios
      .post(
        protocolAndHost + "/api/secured/activity/GetActivityList",
        {
          vfiUser: sessionStorage.getItem("vfiUser"),
          fromDate: this.state.filters.fromDate,
          toDate: this.state.filters.toDate,
        },
        { cancelToken: this._source.token }
      )
      .then((response) => {
        if (this._isMounted) {
          const length = response.data.length;
          if (length > 0) {
            this.setState({
              activity: response.data.reverse(),
              random: new Date().getTime(),
            });
          }
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request was cancelled, ignore this error
        } else {
          console.error(error);
        }
      });
  };

  componentDidMount() {
    this._isMounted = true;
    const protocolAndHost = env.protocol + env.env;

    this.getActivities();

    if (parseInt(this.props.activityUpdate) > 0) {
      axios
        .post(protocolAndHost + "/api/secured/updateActivity", {
          userId: sessionStorage.getItem("vfiUser"),
        })
        .then((response) => {
          this.props.updateActUpt(response.data.Counting);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    axios
      .post(protocolAndHost + "/api/public/GetSites.php", {
        userId: sessionStorage.getItem("vfiUser"),
        ignoreRootPages: true,
      })
      .then((response) => {
        this.setState({ sites: response.data });
      })
      .catch((error) => console.error(error));

    axios
      .get(protocolAndHost + "/api/public/GetRegions.php")
      .then((response) => {
        this.setState({ regions: response.data });
      })
      .catch((error) => console.error(error));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.filters.fromDate !== prevState.filters.fromDate ||
      this.state.filters.toDate !== prevState.filters.toDate ||
      (this.state.show !== prevState.show && this.state.show === "activities")
    ) {
      this.getActivities();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._source.cancel();
  }

  sitesToMap() {
    const sites = [];
    this.props.listOptions.forEach((element) => {
      if (element.index !== "0") {
        sites.push({
          text: element.name,
          selected: this.sitesChecked.includes(element.name),
        });
      }
    });
    return sites;
  }

  handleShowMore() {
    var increment = 30;
    const activityLength = this.state.activity.length;
    this.setState({
      showItems: this.state.showItems + increment >= activityLength ? activityLength : this.state.showItems + increment,
    });
  }

  onCheckboxChange = (e) => {
    // When checking a checkbox we document it and update the array
    const index = this.sitesChecked.indexOf(e.target.name); // Document if object is selected or not
    if (index > -1) {
      this.sitesChecked.splice(index, 1);
    } else {
      this.sitesChecked.push(e.target.name);
    }

    // Update selected
    this.filterContent[1].value = this.filterContent[1].value.map((element) => ({
      text: element.text,
      selected: this.sitesChecked.includes(element.text),
    }));
    this.props.SetState("filterdata.content", this.filterContent); //update the array in main
  };

  onDateChange = (whatdate, date) => {
    this.filterContent[0].value[0][whatdate] = date;

    this.props.SetState("filterdata.content", this.filterContent); //update the array in main
  };

  checkboxFiltering(list, locateAttr) {
    //Filter the list when checkbox in filter changes
    const filter = this.sitesChecked;
    let tempList = [];
    if (filter.includes("all") || filter.length === 0) {
      //If show all or if we are filtering wrong data return all
      tempList = list;
    } else {
      filter.forEach((element) => {
        const filteredList = list.filter((q) =>
          q[locateAttr] !== undefined
            ? q.type === "User"
              ? q[locateAttr].includes(element)
              : q[locateAttr] === element
            : ""
        );
        tempList = tempList.concat(filteredList);
      });
      //   }
    }
    return tempList;
  }

  // onSearchChange = (e) => {
  //   //Change search value
  //   this.search.searchValue = e.target.value;
  //   this.props.SetState("filterdata.search", this.search);

  //   // Change list depending on search
  //   this.filterContent[1].value = helperfunctions.searchFilter(
  //     this.sitesToMap(),
  //     this.search.searchValue
  //   );
  //   this.props.SetState("filterdata.content", this.filterContent);
  // };

  ActivityTable = ({
    content,
    listName = "",
    startShowingLength = 15,
    hidden = false,
    mainClick = () => void 0,
    dropdownButtonClick = () => void 0,
  }) => {
    const [showAmount, setShowAmount] = useState(startShowingLength);
    useEffect(() => {
      if (hidden) {
        setShowAmount(startShowingLength);
      }
    }, [hidden, startShowingLength]);
    if (hidden) return "";
    return (
      <>
        <table className="activity-table">
          <thead>
            <tr>
              <th></th>
              <th
                className={this.state.sortedBy === "date" ? "date sorted-by" : "date"}
                onClick={() => {
                  let reverse = false;
                  if (this.state.sortedBy === "date" && this.state.reverse === false) reverse = true;
                  this.setState({
                    sortedBy: "date",
                    reverse: reverse,
                    activity: helperfunctions.sortList(
                      "activities.0.create_date",
                      this.state.activity,
                      this.state.sortedBy === "date",
                      false,
                      false
                    ),
                  });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Date</div>}
                >
                  Date
                  <FontAwesomeIcon
                    icon={this.state.sortedBy === "date" ? faSortAltSorted : faSortAlt}
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={this.state.sortedBy === "region" ? "region sorted-by" : "region"}
                onClick={() => {
                  let reverse = false;
                  if (this.state.sortedBy === "region" && this.state.reverse === false) reverse = true;
                  this.setState({
                    sortedBy: "region",
                    reverse: reverse,
                    activity: helperfunctions.sortList(
                      "regionName",
                      this.state.activity,
                      this.state.sortedBy === "region"
                    ),
                  });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Region</div>}
                >
                  Region
                  <FontAwesomeIcon
                    icon={this.state.sortedBy === "region" ? faSortAltSorted : faSortAlt}
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={this.state.sortedBy === "activity" ? "activity sorted-by" : "activity"}
                onClick={() => {
                  let reverse = false;
                  if (this.state.sortedBy === "activity" && this.state.reverse === false) reverse = true;
                  this.setState({
                    sortedBy: "activity",
                    reverse: reverse,
                    activity: helperfunctions.sortList(
                      "activities.0.activity",
                      this.state.activity,
                      this.state.sortedBy === "activity"
                    ),
                  });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Activity</div>}
                >
                  Activity
                  <FontAwesomeIcon
                    icon={this.state.sortedBy === "activity" ? faSortAltSorted : faSortAlt}
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={this.state.sortedBy === "approval" ? "approval sorted-by" : "approval"}
                onClick={() => {
                  let reverse = false;
                  if (this.state.sortedBy === "approval" && this.state.reverse === false) reverse = true;
                  this.setState({
                    sortedBy: "approval",
                    reverse: reverse,
                    activity: helperfunctions.sortList(
                      "approval_status",
                      this.state.activity,
                      this.state.sortedBy === "approval"
                    ),
                  });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Approval status</div>}
                >
                  Approval
                  <FontAwesomeIcon
                    icon={this.state.sortedBy === "approval" ? faSortAltSorted : faSortAlt}
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>

              <th className="status">
                <GlobalToolTipController
                  offset={{ x: 10, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Status</div>}
                >
                  Status
                  <FontAwesomeIcon
                    icon={this.state.sortedBy === "status" ? faSortAltSorted : faSortAlt}
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={"loading-data" + (this.state.loading ? "" : " hidden")}>
              <td colSpan={6}>
                <FontAwesomeIcon icon={faSpinner} spin />
              </td>
            </tr>
            {this.mapActivity(content, showAmount, listName, mainClick)}
          </tbody>
        </table>
        <p
          className={this.state.showItems >= content.length ? "SeeMore hide" : "SeeMore"}
          // onClick={() => this.handleShowMore()}
          onClick={() => setShowAmount(showAmount + 30)}
        >
          See more
          <FontAwesomeIcon icon={faChevronDown} />
        </p>
      </>
    );
  };

  mapActivity(searchContent, showAmount, listName, mainClick = () => void 0) {
    const protocolAndHost = env.protocol + env.env;
    var activityList = searchContent.slice(0, showAmount).map((data, i) => {
      var title;
      var icon;
      var location;
      location = <td className="Location">{data.siteName}</td>;

      //Broyta title og location
      if (data.type === "Users") {
        title = (
          <td className="page">
            <p>{data.postName} </p>
            <p className="gray_small">
              {"Applied " + data.time_since_reg + " \u00b7 " + data.userName + " \u00b7 " + data.userCompany}
            </p>
            {/* <p className="gray_small">{"Submitted "+data.time_since_reg +' \u00b7 ' + data.userName + ' \u00b7 ' + data.userCompany}</p> */}
          </td>
        );
        icon = <FontAwesomeIcon icon={faUserPlus} />;
        location = (
          <td className="Location">
            {data.siteName !== undefined
              ? data.siteName.map((elm, i) => (i === data.siteName.length - 1 ? elm : elm + ", "))
              : ""}
          </td>
        );
      } else {
        title = (
          <td className="page" onClick={() => mainClick(data)}>
            {data.thumbnail ? <img src={data.thumbnail} alt="user img" /> : <img src={empty} alt="user img" />}
            <p>{data.title}</p>
            <p className="gray_small">
              {"Updated " +
                showDate(data.activities[0].create_date) +
                " \u00b7 " +
                (data.activities[0].userFullname ?? "No name") +
                (data.placeName ? " \u00b7 " + data.placeName : "")}
            </p>
          </td>
        );
        icon = <FontAwesomeIcon icon={activityIconShow(data.activities[0].type, data.activities[0].activity)} />;
      }

      const thisActivity = data.type + data.id;
      const activityExpanded = this.state.expandedActivities.find((e) => e === thisActivity) !== undefined;

      const typeTranslate = {
        "tour operator": "tour provider",
      };
      let type = data.type;
      type = typeTranslate[data.type] ?? type;

      const theReturn = [
        <tr className={activityExpanded ? "main-tr-expanded" : ""} key={thisActivity}>
          {/* {this.state.expandedActivities.find((e) => e === thisActivity) ? JSON.stringify(data) : ""} */}
          <td
            className="expand"
            onClick={() => {
              this.setState((prevState) => {
                const expandedActivities = prevState.expandedActivities.includes(thisActivity)
                  ? prevState.expandedActivities.filter((e) => e !== thisActivity)
                  : [...prevState.expandedActivities, thisActivity];
                return { expandedActivities };
              });
            }}
          >
            {activityExpanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
          </td>
          {title}
          {/* {location} */}
          <td className="site">{data.regionName ?? "All regions"}</td>
          <td className="Activity">
            {icon}
            {capitalize(type)} {data.activities[0].activity_show?.toLowerCase()}
          </td>
          <td className="Approval">
            {/* {data.status} */}
            {data.status === "pending" ? (
              <WhatsonApprovalStatusButtons
                element={{ ...data, approvalStatus: data.approval_status }}
                whatWhatson={data.type}
                onChange={(e) => {
                  this.setState((prevState) => {
                    const activity = [...prevState.activity];
                    const index = activity.findIndex((a) => a.id === e.id && a.type === e.type);
                    activity[index] = { ...e, status: e.approval_status };
                    return { activity };
                  });
                }}
                clickFunc={(s) => {
                  if (["changes required", "deny"].includes(s)) {
                    this.setState({
                      changeDecisionInfo: {
                        type: data.type,
                        id: data.id,
                        value: s,
                        state: "mail",
                      },
                    });
                  } else {
                    approvalStatusChange(data.type, data.id, s, (response) => {
                      if (response.data.success) {
                        this.setState((prevState) => {
                          const activity = [...prevState.activity];
                          const index = activity.findIndex((a) => a.id === data.id && a.type === data.type);
                          activity[index] = {
                            ...activity[index],
                            status: s,
                            approval_status: s,
                          };
                          return { activity };
                        });
                      }
                    });
                  }
                }}
              />
            ) : (
              ""
            )}
            {["approve", "changes required", "deny"].includes(data.approval_status) ? (
              <div>
                <div>
                  {data.approval_status === "approve" ? "Approved" : ""}
                  {data.approval_status === "changes required" ? "Changes required" : ""}
                  {data.approval_status === "deny" ? "Deleted" : ""}
                </div>
                <div
                  className="change-decision-button"
                  onClick={() => {
                    this.setState({
                      changeDecisionInfo: {
                        type: data.type,
                        id: data.id,
                        value: data.approval_status,
                        state: "index",
                      },
                    });
                  }}
                >
                  Change decision
                </div>
              </div>
            ) : (
              ""
            )}
          </td>

          <td className="status">{data.status ? capitalize(data.status) : "No status"}</td>
        </tr>,
      ];
      if (this.state.expandedActivities.find((e) => e === thisActivity)) {
        theReturn.push(
          <tr key={thisActivity + "expanded"} className={"expanded head"}>
            <td></td>
            <td>Version by</td>
            <td></td>
            <td>Activity</td>
            <td>Date</td>
            <td></td>
          </tr>
        );
        const amountShown = this.state.expandedShownAmount[thisActivity] ?? 5;
        data.activities.forEach((e, i) => {
          if (i < amountShown)
            theReturn.push(
              <tr key={e.id + "expanded"} className={"expanded"}>
                <td></td>
                <td>
                  {e.userFullname ?? "No name"}
                  {" \u00b7 "}
                  {e.posts}
                </td>
                <td></td>
                <td className="Activity">
                  <FontAwesomeIcon icon={activityIconShow(e.type, e.activity)} />
                  {capitalize(type)} {e.activity_show?.toLowerCase()}
                </td>
                <td>{showDate(e.create_date)}</td>
                <td></td>
              </tr>
            );
        });
        if (amountShown < data.activities.length)
          theReturn.push(
            <tr
              key={thisActivity + "show-more"}
              className="expanded show-more"
              onClick={() => {
                this.setState({
                  expandedShownAmount: {
                    ...this.state.expandedShownAmount,
                    [thisActivity]: amountShown !== undefined ? amountShown + 5 : 5,
                  },
                });
              }}
            >
              <td></td>
              <td>Show more</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          );
      }

      return theReturn;
    });

    return activityList;
  }

  showFilters = () => {
    const filterData = this.state.filters;

    const activityTypes = this.state.activityTypes.map((type) => type.endpointTypeName);
    const awaitingStatus = ["Pending", "Approved", "Changes required", "Denied"];

    return [
      <VfiInputText
        key="search"
        className="search-box"
        value={this.state.filters.searchValue}
        placeholder="Search..."
        onChange={(e) => {
          this.setState({
            filters: { ...this.state.filters, searchValue: e.target.value },
          });
        }}
        isSearch={true}
      />,
      {
        type: "dropdown",
        title: "DATE",
        content: (
          <div key={"date-from-to"} className="date-from-to">
            <label>
              <ReactDatePicker
                maxDate={this.state.filters.toDate}
                dateFormat="dd/MM/yy"
                selected={this.state.filters.fromDate}
                onChange={(date) => {
                  const filterData = this.state.filters;
                  this.setState({
                    filters: { ...filterData, fromDate: date },
                  });
                }}
                // popperModifiers={[]}
              />
              {/* <FontAwesomeIcon icon={faCalendarDays} /> */}
            </label>
            <div className="to-text">to</div>
            <label>
              <ReactDatePicker
                minDate={this.state.filters.fromDate}
                maxDate={new Date()}
                dateFormat="dd/MM/yy"
                selected={this.state.filters.toDate}
                onChange={(date) => {
                  const filterData = this.state.filters;
                  this.setState({
                    filters: { ...filterData, toDate: date },
                  });
                }}
                popperModifiers={[]}
              />
              {/* <FontAwesomeIcon icon={faCalendarDays} /> */}
            </label>
          </div>
        ),
        defaultCollapsed: false,
      },
      {
        type: "dropdown",
        title: "SITE",
        content: (
          <div key={"filter-sites"} className="filters">
            <div className="filter">
              <label>
                <VfiCheckbox
                  checked={filterData.sites.length === 0}
                  onChange={() => {
                    this.setState({ filters: { ...filterData, sites: [] } });
                  }}
                />
                <span className={"text" + (filterData.sites.length === 0 ? " active" : "")}>All</span>
              </label>
            </div>
            {this.state.sites.map((site) => {
              const checked = this.state.filters.sites.find((name) => name === site.name) !== undefined;
              return (
                <div className="filter" key={site.siteId}>
                  <label>
                    <VfiCheckbox
                      onChange={(e) => {
                        let sites = clone(filterData.sites);
                        if (e.target.checked) {
                          sites.push(site.name);
                        } else {
                          sites = sites.filter((f) => f !== site.name);
                        }
                        this.setState({
                          filters: { ...filterData, sites },
                        });
                      }}
                      checked={checked}
                    />
                    <span className={"text" + (checked ? " active" : "")}>{site.name}</span>
                  </label>
                </div>
              );
            })}
          </div>
        ),
        defaultCollapsed: true,
      },
      {
        type: "dropdown",
        title: "REGION",
        content: (
          <div key={"filter-regions"} className="filters">
            <div className="filter">
              <label>
                <VfiCheckbox
                  checked={filterData.regions.length === 0}
                  onChange={() => {
                    this.setState({ filters: { ...filterData, regions: [] } });
                  }}
                />
                <span className={"text" + (filterData.regions.length === 0 ? " active" : "")}>All</span>
              </label>
            </div>
            {this.state.regions.map((region) => {
              const checked = this.state.filters.regions.find((name) => name === region.region_name) !== undefined;
              return (
                <div className="filter" key={region.region_id}>
                  <label>
                    <VfiCheckbox
                      onChange={(e) => {
                        let regions = clone(filterData.regions);
                        if (e.target.checked) {
                          regions.push(region.region_name);
                        } else {
                          regions = regions.filter((f) => f !== region.region_name);
                        }
                        this.setState({
                          filters: { ...filterData, regions: regions },
                        });
                      }}
                      checked={checked}
                    />
                    <span className={"text" + (checked ? " active" : "")}>{region.region_name}</span>
                  </label>
                </div>
              );
            })}
          </div>
        ),
        defaultCollapsed: true,
      },
      {
        type: "dropdown",
        title: "ACTIVITY TYPE",
        content: (
          <div key={"filter-activity-types"} className="filters">
            <div className="filter">
              <label>
                <VfiCheckbox
                  checked={filterData.activity_types.length === 0}
                  onChange={() => {
                    this.setState({
                      filters: { ...filterData, activity_types: [] },
                    });
                  }}
                />
                <span className={"text" + (filterData.activity_types.length === 0 ? " active" : "")}>All</span>
              </label>
            </div>
            {activityTypes.map((aType) => {
              const checked = this.state.filters.activity_types.find((name) => name === aType) !== undefined;
              return (
                <div className="filter" key={aType}>
                  <label>
                    <VfiCheckbox
                      onChange={(e) => {
                        let aTypes = clone(filterData.activity_types);
                        if (e.target.checked) {
                          aTypes.push(aType);
                        } else {
                          aTypes = aTypes.filter((f) => f !== aType);
                        }
                        this.setState({
                          filters: { ...filterData, activity_types: aTypes },
                        });
                      }}
                      checked={checked}
                    />
                    <span className={"text" + (checked ? " active" : "")}>{capitalize(aType)}</span>
                  </label>
                </div>
              );
            })}
          </div>
        ),
        defaultCollapsed: true,
      },
      {
        type: "dropdown",
        title: "AWAITING STATUS",
        content: (
          <div key={"filter-awaiting-status"} className="filters">
            <div className="filter">
              <label>
                <VfiCheckbox
                  checked={filterData.awaiting_status.length === 0}
                  onChange={() => {
                    this.setState({
                      filters: { ...filterData, awaiting_status: [] },
                    });
                  }}
                />
                <span className={"text" + (filterData.awaiting_status.length === 0 ? " active" : "")}>All</span>
              </label>
            </div>
            {awaitingStatus.map((aStauses) => {
              const checked = this.state.filters.awaiting_status.find((name) => name === aStauses) !== undefined;
              return (
                <div className="filter" key={aStauses}>
                  <label>
                    <VfiCheckbox
                      onChange={(e) => {
                        let aStatus = clone(filterData.awaiting_status);
                        if (e.target.checked) {
                          aStatus.push(aStauses);
                        } else {
                          aStatus = aStatus.filter((f) => f !== aStauses);
                        }
                        this.setState({
                          filters: { ...filterData, awaiting_status: aStatus },
                        });
                      }}
                      checked={checked}
                    />
                    <span className={"text" + (checked ? " active" : "")}>{aStauses}</span>
                  </label>
                </div>
              );
            })}
          </div>
        ),
        defaultCollapsed: true,
      },
    ];
    // awaiting_status;
  };

  mainClickFunction = (data) => {
    switch (data.type) {
      case "page":
      case "article":
        axios(env.protocol + env.env + "/api/public/pages/GetPage.php?update=true&id=" + data.id)
          .then((response) => {
            this.setState({ pageData: response.data, show: "page" });
          })
          .catch((error) => console.error(error));
        break;
      case "event":
      case "place":
      case "hiking":
      case "hike":
      case "tour":
      case "tour operator":
        this.setState({
          show: "preview_whatson",
          whatWhatson: data.type,
          whatsonId: data.id,
        });
        break;
      case "user":
        // this.setState({
        //   show: "user",
        // });
        axios
          .post(env.protocol + env.env + "/api/secured/GetUser", {
            vfiUser: data.userId,
          })
          .then((response) => {
            this.props.openOverlay("userEdit", {
              ...response.data,
              id: response.data.userId.toString(),
            });
          })
          .catch((error) => console.error(error));
        break;
      case "media":
        this.setState({
          show: "media",
          media_opened: pushMedia(data),
          media_opened_edit: pushMedia(data),
        });
        break;
      default:
        console.error(`The type '${data.type}' isn't a correct type.`);
        break;
    }
  };

  // Filter
  filterContentAction = (activities, excludeFiltering = []) => {
    const filters = this.state.filters;

    // Search input
    if (!excludeFiltering.includes("searchValue")) {
      activities = helperfunctions.searchFilter(activities, filters.searchValue);
    }

    if (!excludeFiltering.includes("showTable")) {
      if (this.state.showTable === "awaiting") {
        activities = activities.filter((f) => {
          return (
            (["awaiting approval", "awaiting approval update", "awaiting"].includes(f.approval_status) ||
              f.submits?.length > 0) &&
            f.current_status === "published"
          );
        });
      }
    }

    // Date
    // activities = activities.filter((f) => {
    //   const date = new Date(f.activities[0].create_date);
    //   const fromDate = new Date(filters.fromDate);
    //   fromDate.setDate(fromDate.getDate() - 1);
    //   return date >= fromDate && date < filters.toDate;
    // });

    // Sites
    if (!excludeFiltering.includes("sites")) {
      if (filters.sites.length !== 0) {
        activities = activities.filter((e) => {
          return filters.sites.find((siteName) => e.siteInfo?.name === siteName) !== undefined;
        });
      }
    }

    // Regions
    if (!excludeFiltering.includes("regions")) {
      if (filters.regions.length !== 0) {
        activities = activities.filter((e) => {
          return filters.regions.find((regionName) => e.regionName === regionName) !== undefined;
        });
      }
    }

    if (!excludeFiltering.includes("activity_types")) {
      // Activity types
      if (filters.activity_types.length !== 0) {
        activities = activities.filter((e) => {
          return filters.activity_types.find((type) => e.type === type) !== undefined;
        });
      }
    }

    // Awaiting status
    if (!excludeFiltering.includes("awaiting_status")) {
      if (filters.awaiting_status.length !== 0) {
        activities = activities.filter((e) => {
          return (
            filters.awaiting_status.find((status) => {
              switch (status) {
                case "Pending":
                  return ["awaiting approval", "awaiting approval update"].includes(e.approval_status);
                case "Approved":
                  return e.approval_status === "approve";
                case "Changes required":
                  return e.approval_status === "changes required";
                case "Denied":
                  return e.approval_status === "deny";
                default:
                  return false;
              }
            }) !== undefined
          );
        });
      }
    }

    return activities;
  };

  render() {
    if (this.state.show === "page") {
      return (
        <EditPage
          data={this.state.pageData}
          close={() => this.setState({ show: "activities" })}
          goBackText={"Activity"}
        />
      );
    }

    if (this.state.show === "preview_whatson") {
      return (
        <PreviewWhatson
          whatWhatson={this.state.whatWhatson}
          whatsonId={this.state.whatsonId}
          close={() => {
            this.setState({ show: "activities" });
            // this.getAllWhatsonList();
          }}
          openOverlay={this.props.openEdit}
          setSiteRef={this.props.setSiteRef}
          addOne={this.props.addOne}
        />
      );
    }

    if (this.state.show === "media") {
      return (
        <div className="media-content">
          <div className="media-display-parent">
            <div className="media-display">
              <MediaSettings
                media={this.state.media_opened}
                editMedia={this.state.media_opened_edit}
                closeSettings={() => this.setState({ show: "activities" })}
                editSettings={(key, value) => {
                  key = key.split(".");
                  const media = cloneDeep(this.state.media_opened_edit);
                  let mediaDive = media;
                  for (let i = 0; i < key.length; i++) {
                    if (i + 1 !== key.length) {
                      mediaDive = mediaDive[key[i]];
                    } else {
                      mediaDive[key[i]] = value;
                    }
                  }
                  this.setState({ media_opened_edit: media });
                }}
                updateClick={() => {}}
                deleteMedia={(id) => {
                  if (window.confirm("Are you sure you want to delete this media?\nThis action cannot be undone.")) {
                    axios
                      .post(env.protocol + env.env + "/api/secured/deleteMedia", {
                        id,
                      })
                      .then((response) => {
                        this.setState({
                          activity: this.state.activity.filter(
                            (f) => f.type !== "media" || f.id !== response.data.deletedIds[0]
                          ),
                          show: "activities",
                        });
                      })
                      .catch((error) => console.error(error));
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    // if (this.state.show === "user") {
    //   return "";
    // } // User opens as overlay

    const filterHidden =
      this.props.addFilterDroppedDown[0] && this.props.addFilterDroppedDown[1] === "activityDisplay" ? "" : "hidden";
    let filteredContent = this.filterContentAction(this.state.activity);
    // searchContent = this.checkboxFiltering(searchContent, "siteName");
    // var date = this.filterContent[0].value[0];
    // searchContent = helperfunctions.filterDateInterval(
    //   searchContent,
    //   date.startDate,
    //   date.endDate
    // );

    // filteredContent = filteredContent.filter((e) => {
    //   if (this.state.filters.activity_types.length === 0) return true;
    //   return this.state.filters.activity_types.includes(e.type);
    // });

    let awaitingSearchContent = helperfunctions.searchFilter(
      this.state.awaiting_approval,
      this.state.filters.searchValue
    );

    const newEventsAmount = this.state.activity.filter((e) => e.type === "event" && e.status === "pending").length;
    const updateEventsAmount = this.state.activity.filter((e) => e.type === "event" && e.submits.length !== 0).length;
    const newPlacesAmount = this.state.activity.filter((e) => e.type === "place" && e.status === "pending").length;
    const updatePlacesAmount = this.state.activity.filter((e) => e.type === "place" && e.submits.length !== 0).length;
    const newToursAmount = this.state.activity.filter((e) => e.type === "tour" && e.status === "pending").length;
    const updateToursAmount = this.state.activity.filter((e) => e.type === "tour" && e.submits.length !== 0).length;
    const newTourOperatorsAmount = this.state.activity.filter(
      (e) => e.type === "tour operator" && e.status === "pending"
    ).length;
    // const updateTourOperatorsAmount = this.state.activity.filter(
    //   (e) => e.type === "tour operator" && e.submits.length !== 0
    //   ).length; // Tour operator does not have submit..

    return (
      <div className="activity-display">
        <OptionsList
          isOpen={this.state.listIsOpen}
          close={() => {
            this.setState({ listIsOpen: false });
          }}
          options={this.state.optionsListOptions}
          position={this.state.optionsListPos}
        />
        <div className="header-panel">
          <div className="header-title">
            <h1>Activity</h1>
          </div>
          <div className="all-sort-and-filter">
            <ToolTip
              offset={{ x: 30, y: 30 }}
              title=""
              delay={600}
              toolTipElements={<div>Toggle filter panel</div>}
              className={"filter-button " + filterHidden}
              onClick={() => {
                // this.filterContent[1].value = this.sitesToMap();
                // this.props.openCloseFilter(
                //   [!this.props.addFilterDroppedDown[0], "activityDisplay"],
                //   {
                //     type: "activity",
                //     search: this.search,
                //     style: {},
                //     content: this.filterContent,
                //     executeCheckbox: (e) => {
                //       // When elements are clicked
                //       this.onCheckboxChange(e);
                //     },
                //     executeDate: (whatdate, date) => {
                //       // When date changes
                //       this.onDateChange(whatdate, date);
                //     },
                //     executeSearch: (e) => {
                //       // When search value changes
                //       this.onSearchChange(e);
                //     },
                //   }
                // );
                this.setState({ filterShown: !this.state.filterShown });
              }}
            >
              <span className="text">Filter</span>
              <FontAwesomeIcon className="slide-icon" icon={faSlidersH} flip={"vertical"} />
            </ToolTip>
          </div>
          <div className="search-parent">
            <div className="search">
              <VfiInputText
                value={this.state.filters.searchValue}
                placeholder="Search for activity..."
                onChange={(e) => {
                  this.setState({
                    filters: {
                      ...this.state.filters,
                      searchValue: e.target.value,
                    },
                  });
                }}
                isSearch={true}
              />
            </div>
          </div>
        </div>
        <div className="table-select">
          <div
            className={`t-option ${this.state.showTable === "awaiting" ? "active" : ""}`}
            onClick={() => {
              this.setState({ showTable: "awaiting" });
            }}
          >
            Awaiting
          </div>
          <div
            className={`t-option ${this.state.showTable === "all" ? "active" : ""}`}
            onClick={() => {
              this.setState({ showTable: "all" });
            }}
          >
            All activity
          </div>
          <div className="t-option rest"></div>
        </div>
        <div className="filter-what">
          <div
            className={`f-option ${
              // this.state.filterOption === "all"
              this.state.filters.activity_types.length === 0 ? "active" : ""
            }`}
            onClick={() =>
              this.setState({
                // filterOption: "all"
                filters: { ...this.state.filters, activity_types: [] },
              })
            }
          >
            <div className="f-text">All</div>
            {/* <div className="notify new-notify">3</div>
            <div className="notify update-notify">1</div> */}
          </div>
          {filteredContent.length > 0 && "|"}
          {filteredContent.length > 0 &&
            this.state.activityTypes
              .filter(
                (e) =>
                  this.filterContentAction(this.state.activity, ["activity_types"]).find(
                    (content) => e.endpointTypeName === content.type
                  ) !== undefined
              )
              .map((activityType) => {
                // return <div key={activityType.name} className="`f-option ${}`">aa</div>;
                const newAmount = this.state.activity.filter(
                  (e) =>
                    e.type === activityType.endpointTypeName &&
                    e.status === "pending" &&
                    e.approval_status === "awaiting approval" &&
                    e.current_status === "published"
                ).length;
                const updateAmount = this.state.activity.filter((e) => {
                  return (
                    e.type === activityType.endpointTypeName &&
                    e.status === "pending" &&
                    e.approval_status === "awaiting approval update" &&
                    e.current_status === "published"
                  );
                }).length;
                return (
                  <div
                    key={activityType.name}
                    className={`f-option ${
                      this.state.filters.activity_types.find((e) => e === activityType.endpointTypeName) !== undefined
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        filters: {
                          ...this.state.filters,
                          activity_types: [activityType.endpointTypeName],
                        },
                      });
                    }}
                  >
                    <div className="f-text">{activityType.name}</div>
                    {newAmount > 0 && <div className="notify new-notify">{newAmount}</div>}
                    {updateAmount > 0 && <div className="notify update-notify">{updateAmount}</div>}
                  </div>
                );
              })
              .reduce((prev, curr) => [prev, "|", curr])}
        </div>
        <this.ActivityTable
          content={filteredContent}
          extraThs={[
            {
              key: 0,
              className: this.state.sortedBy === "date" ? "date sorted-by" : "date",
              content: (
                <div>
                  Date{" "}
                  <FontAwesomeIcon
                    icon={this.state.sortedBy === "date" ? faSortAltSorted : faSortAlt}
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </div>
              ),
              onClick: () => {
                helperfunctions.sortList("regdate", filteredContent, this.state.sortedBy === "date", true);
                let reverse = false;
                if (this.state.sortedBy === "date" && this.state.reverse === false) reverse = true;
                this.setState({ sortedBy: "date", reverse: reverse });
              },
            },
          ]}
          extraTds={[
            {
              key: 0,
              className: "Date",
              content: (data) => <div>{data.time_since_reg}</div>,
              onClick: () => {},
            },
          ]}
          hidden={this.state.allActivityHidden}
          listName="activity"
          mainClick={this.mainClickFunction}
        />
        <RightSideInfo
          info={this.showFilters()}
          title="Filter"
          isShowing={this.state.filterShown}
          close={() => this.setState({ filterShown: false })}
          // defaultAllCollapsed={true}
        />
        {Boolean(this.state.changeDecisionInfo?.id) &&
          ((changeDecisionInfo) => {
            const approvalScreenText = {
              "changes required": {
                title: "Require changes",
                subText: undefined,
                messageHeadline: "TELL THE SUBMITTER WHAT TO CHANGE",
                messagePlaceholder: "You should ...",
                saveButtonText: "SEND",
                saveButtonStyle: undefined,
              },
              deny: {
                title: "Delete draft",
                subText:
                  changeDecisionInfo.state === "mail" ? (
                    <>
                      Keep in mind, this will delete the new draft. The published version of this{" "}
                      {changeDecisionInfo.type} will remain as is. <b>Once deleted, this action cannot be undone.</b>
                    </>
                  ) : undefined,
                messageHeadline: "ANY SPECIFIC REASON? (OPTIONAL)",
                messagePlaceholder: "This draft cannot be accepted because ...",
                saveButtonText: "DELETE DRAFT",
                saveButtonStyle: {
                  backgroundColor: "#CF323B",
                  letterSpacing: "1px",
                },
              },
            };
            return (
              <ChangeApprovalScreen
                defaultInfo={changeDecisionInfo}
                close={() => {
                  this.setState({ changeDecisionInfo: undefined });
                }}
                updateApprovalStatus={(type, id, value) => {
                  const activities = cloneDeep(this.state.activity);
                  const activity = activities.find((a) => a.type === type && a.id === id);
                  if (activity !== undefined) {
                    activity.approval_status = value;
                    activity.status = value;
                    this.setState({ activity: activities });
                  }
                  this.getActivities();
                }}
                title={approvalScreenText[changeDecisionInfo.value]?.title}
                subText={approvalScreenText[changeDecisionInfo.value]?.subText}
                messageHeadline={approvalScreenText[changeDecisionInfo.value]?.messageHeadline}
                messagePlaceholder={approvalScreenText[changeDecisionInfo.value]?.messagePlaceholder}
                saveButtonText={approvalScreenText[changeDecisionInfo.value]?.saveButtonText}
                saveButtonStyle={approvalScreenText[changeDecisionInfo.value]?.saveButtonStyle}
                infoChange={(info) => {
                  this.setState({ changeDecisionInfo: info });
                }}
              />
            );
          })(this.state.changeDecisionInfo)}
      </div>
    );
  }
}
export default Activity;
