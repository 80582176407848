import torshavnRegions from "src/assets/TorshavnRegions.json";
import { IZip } from "src/views/WhatsOn/stores/location-store/LocationStore";
import ZIP_CODES from "src/assets/zipCodes.json";

const REGION_MAP: Partial<Record<string, string>> = {
  Eysturoyar: "Eysturoy",
  "Northern Isles": "Norðoyggjar",
  Streymoyar: "Streymoy",
  Suduroy: "Suðuroy",
  Suðuroyar: "Suðuroy",
  Sandoyar: "Sandoy",
  "Vága Municipality": "Vágar",
  Norðoya: "Norðoyggjar",
};

interface IFindRegionAndZip {
  region: undefined | string;
  zip: undefined | IZip;
  street: undefined | string;
  number: undefined | number;
}

export const findRegionAndZip = (geocodeData: google.maps.GeocoderResult[]): IFindRegionAndZip => {
  let region: string | undefined = undefined;
  let zipValue: number | undefined = undefined;
  let street: string | undefined = undefined;
  let number: number | undefined = undefined;

  for (let i = 0; i < geocodeData.length; i++) {
    geocodeData[i].address_components.forEach((element) => {
      element.types.forEach((type) => {
        if (type === "postal_code" && zipValue === undefined) {
          zipValue = parseInt(element.long_name);
        }
        if (type === "administrative_area_level_1" && region === undefined) {
          region = element.long_name;
        }
        if (type === "route" && street === undefined) {
          street = element.long_name;
        }
        if (type === "street_number" && number === undefined) {
          number = parseInt(element.long_name);
        }
      });
    });
  }
  region = REGION_MAP[region ?? ""] ?? region;
  if (torshavnRegions.includes(zipValue ?? -1)) {
    region = "Tórshavn";
  }
  let zip = undefined;
  if (zipValue) {
    for (const [, value] of Object.entries(ZIP_CODES)) {
      if (zipValue === value.code) {
        zip = { code: value.code.toString(), city: value.city };
      }
    }
  }
  return { region, zip, street, number };
};
